<template>
	<modal class="NoxModalPortraitTests" name="NoxModalPortraitTests" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_open_loading" v-if="noxIsOpenLoading"><div class="nox_loading"></div></div>
			<div class="nox_modal_open" v-else>
				<div class="nox_modal_head">
					<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
					<div class="nox_modal_title">
						<span v-if="noxIsPortraitResult" v-html="$store.getters.getLanguageText('5.14', 1)"></span>
						<span v-else v-html="$store.getters.getLanguageText('5.14', 0, { id: (noxPortraitQuestionsPassed + 1), count: noxPortraitQuestionsCount })"></span>
					</div>
				</div>
				<div class="nox_modal_body">
					<div class="nox_modal_info">
						<div v-if="noxIsPortraitResult">
							<div v-if="Object.keys(noxPortraitResult).length">
								<div class="nox_modal_info_portrait_result_progress">
									<div class="nox_modal_info_portrait_result_progress_title" v-html="$store.getters.getLanguageText('5.14', 2)"></div>
									<div class="nox_modal_info_portrait_result_progress_block">
										<div class="nox_modal_info_portrait_result_progress_value" :style="'background: #' + noxPortraitResult.color + '; width: ' + getPortraitResultProgress() + '%;'">{{ getPortraitResultProgress() }}%</div>
									</div>
								</div>
								<div v-html="($store.state.noxLanguage == 'en' ? noxPortraitResult.result_en : noxPortraitResult.result_ru)"></div>
							</div>
						</div>
						<div v-else-if="Object.keys(noxPortraitQuestion).length">
							<p v-html="($store.state.noxLanguage == 'en' ? noxPortraitQuestion.question_en : noxPortraitQuestion.question_ru)"></p>
							<p class="nox_p_radios" v-if="noxPortraitAnswers.length">
								<label class="nox_radio" :for="'nox_radio_portrait_answer_' + index" v-for="(value, index) in noxPortraitAnswers" :key="index">
									<input type="radio" :id="'nox_radio_portrait_answer_' + index" name="nox_radio_portrait_answer" :value="value" v-model.number="noxPortraitAnswer">
									<span class="nox_radio_mark"></span>
									<span class="nox_radio_text" v-html="($store.state.noxLanguage == 'en' ? value.answer_en : value.answer_ru)"></span>
								</label>
							</p>
							<div v-html="noxAlertPortraitAnswer"></div>
						</div>
					</div>
					<div class="nox_modal_alert" v-html="noxAlert"></div>
					<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
						<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
						<button type="button" class="nox_button common green" @click="axios('addPortraitTest')" v-if="!noxIsPortraitResult" v-html="$store.getters.getLanguageText('1.1', 64)"></button>
					</div>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxAlertPortraitAnswer: '',
			noxPortraitQuestion: {},
			noxPortraitQuestionsCount: 0,
			noxPortraitQuestionsPassed: 0,
			noxPortraitAnswers: [],
			noxPortraitAnswer: {},
			noxPortraitResult: {},
			noxIsPortraitResult: false,
			noxIsOpenLoading: false,
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function() {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxPortraitQuestion = {};
				this.noxPortraitQuestionsCount = 0;
				this.noxPortraitQuestionsPassed = 0;
				this.noxPortraitAnswers = [];
				this.noxPortraitAnswer = {};
				this.noxPortraitResult = {};
				this.noxIsPortraitResult = false;
				this.noxIsOpenLoading = true;
				this.noxIsLoading = false;
				this.noxIsError = false;

				this.axios('getPortraitTest');
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertPortraitAnswer = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.16', 0); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.16', 1); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.16', 2); }
				else if (i == 4) { this.noxTemp = this.$store.getters.getLanguageText('1.3.16', 3); }
				else if (i == 5) { this.noxTemp = this.$store.getters.getLanguageText('1.3.16', 4); }
				else if (i == 6) { this.noxTemp = this.$store.getters.getLanguageText('1.3.16', 5); }
				else             { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			getPortraitResultProgress: function() {
				return Math.round(this.noxPortraitResult.total_weight * 100 / this.$store.state.noxSystemSettings.portrait_max_weight);
			},
			axios: function(type) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsPortraitResult = false;
					_this.noxIsError = false;

					if (type == 'getPortraitTest') {
						config.url = '/v2/account/portrait/tests';
					}
					else if (type == 'addPortraitTest') {
						if (_this.noxPortraitAnswer.id == undefined) {
							_this.noxAlertPortraitAnswer = _this.getError(3);
						}
						else if (!_this.$store.state.noxRegex_d.test(_this.noxPortraitAnswer.id) || _this.noxPortraitAnswer.id < 1) {
							_this.noxAlertPortraitAnswer = _this.getError(4);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/account/portrait/tests';
							config.data = { qid: _this.noxPortraitQuestion.id, aqid: _this.noxPortraitAnswer.id };
							config.method = 'post';
						}
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (['getPortraitTest', 'addPortraitTest'].includes(type)) {
									if (data.data.portrait_question !== undefined) {
										_this.noxPortraitQuestion = data.data.portrait_question;
									}
									if (data.data.portrait_questions_count !== undefined) {
										_this.noxPortraitQuestionsCount = data.data.portrait_questions_count;
									}
									if (data.data.portrait_questions_passed !== undefined) {
										_this.noxPortraitQuestionsPassed = data.data.portrait_questions_passed;
									}
									if (data.data.portrait_answers !== undefined) {
										_this.noxPortraitAnswers = data.data.portrait_answers;
										_this.noxPortraitAnswer = {};
									}
									if (data.data.portrait_result !== undefined) {
										_this.noxPortraitResult = data.data.portrait_result;
										_this.noxIsPortraitResult = true;
									}
								}
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (type == 'addPortraitTest') {
										if      (data.response.data.error == 'QID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'QID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
										else if (data.response.data.error == 'AQID_IS_EMPTY') { _this.noxAlertPortraitAnswer = _this.getError(3); }
										else if (data.response.data.error == 'AQID_NOT_VALID') { _this.noxAlertPortraitAnswer = _this.getError(4); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (type == 'addPortraitTest') {
										if      (data.response.data.error == 'QID_NOT_FOUND') { _this.noxAlert = _this.getError(5); }
										else if (data.response.data.error == 'AQID_NOT_FOUND') { _this.noxAlert = _this.getError(6); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsOpenLoading = false;
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			close: function() {
				this.$modal.hide('NoxModalPortraitTests');
			}
		}
	}
</script>
